/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    /**
     * Universal click or tap event
     * (replacement for 'click')
     */

    var clickEvent = function () {
        return ('undefined' !== typeof window.touchstart ? 'touchstart' : 'click');
    };

    /**
     * Mobile
     * Window width
     */

    function mobileCheck() {
        if ( '768' >= winWidth ) {
            mobile = true;
        } else {
            mobile = false;
        }

        if ( '480' >= winWidth ) {
            mobilePhone = true;
        } else {
            mobilePhone = false;
        }
    }

    function smHeightCheck() {
        if ( mobilePhone && '480' >= winHeight ) {
            smHeight = true;
        } else {
            smHeight = false;
        }
    }

    var winWidth    = $(window).width(),
        winHeight   = $(window).height(),
        mobile      = false,
        mobilePhone = false,
        smHeight    = false;

    mobileCheck();
    smHeightCheck();

    $(window).resize(function() {
        winWidth  = $(window).width();
        winHeight = $(window).height();
        mobileCheck();
        smHeightCheck();
    });

    /**
     * Browser detection
     */

    function isIE() {
         return ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null)));
    }

    var isIEOld     = isIE() && isIE() < 9,
        isiPad      = navigator.userAgent.match(/iPad/i),
        isChrome    = navigator.userAgent.indexOf('Chrome') > -1,
        isChromeiOS = navigator.userAgent.match('CriOS'),
        isSafari    = false;

    if ( (navigator.userAgent.indexOf('Safari') > -1) && ! isChrome && ! isChromeiOS ) {
        isSafari = true;
    }

    /**
     * Main
     */

    var Delta = {
        // All pages
        'common': {
            init: function() { // JavaScript to be fired on all pages

                /* @TODO: Page transitions */

                /**
                 *  If mobile, and viewport height is a certain size, add keyboard active class to body
                 */
                $('input').on(clickEvent(), function(e) {
                    winHeight = $(window).height();
                    smHeightCheck();
                });

                if ( smHeight ) {
                    $('body').addClass('small-height');
                    $('.modal').find('.modal-header').velocity('slideUp');
                } else {
                    $('.modal').find('.modal-header').velocity('slideDown');
                }

                /* App navigation */

                var $navTrigger = $('.app-nav-trigger');

                $navTrigger.sideNav({
                    menuWidth: 260 // Default is 240
                });

                $navTrigger.on(clickEvent(), function() {
                    Materialize.showStaggeredList('#app-nav .staggered-list');
                });

                /* Modals */

                $('.modal-trigger').on(clickEvent(), function() {
                    $('.modal.open').closeModal();
                });

                $('.modal-trigger').leanModal({opacity: 0.8});

                /* Flyouts */

                var closeFlyouts = function() {
                    var $flyouts = $('.flyout');

                    $flyouts.each(function(i) {
                        if ( $(this).hasClass('active') ) {
                            $(this).velocity('fadeOut', { duration: 250 }).removeClass('active');
                        }
                    });
                };

                $('.flyout-trigger').on(clickEvent(), function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var flyoutId = $(this).attr('href');

                    closeFlyouts();
                    $(flyoutId).velocity('fadeIn', { duration: 300 }).addClass('active');
                });

                $(document).on(clickEvent(), function(e) {
                    closeFlyouts();
                });

                /* Forms */

                // select

                $('select').material_select();

                // input reveal

                var $reveal = $('.input-reveal');

                $reveal.each(function () {
                    var syncRevealTarget = function ($checkbox) {
                            var $revealTrigger = $checkbox.parents('.reveal-trigger'),
                                revealTargetId = $revealTrigger.data('reveal-target');

                            if ( $checkbox.is(':checked') ) {
                                $('#' + revealTargetId).velocity('slideDown');
                            } else {
                                $('#' + revealTargetId).velocity('slideUp');
                            }
                        },
                        $checkbox        = $(this).find('input[type="checkbox"]');

                    syncRevealTarget($checkbox);

                    $checkbox.on('change', function () { syncRevealTarget($checkbox); });
                });

                /* Select lists */

                $('.select-list > li > a').on(clickEvent(), function(e) {
                    e.preventDefault();

                    var $listLinks   = $('.select-list > li > a'),
                        $btnContinue = $('.select-list').parents('.modal').find('.btn-continue');

                    if ( $(this).hasClass('active') ) {
                        $(this).removeClass('active');
                        $btnContinue
                            .prop('disabled', true) // <button>
                            .addClass('disabled'); // <a>

                    } else {
                        $listLinks.each(function() {
                            $(this).removeClass('active');
                        });
                        $(this).addClass('active');
                        $btnContinue
                            .prop('disabled', false) // <button>
                            .removeClass('disabled'); // <a>
                    }
                });

                /* Scrollspy */

                $('.scrollspy').scrollSpy();

                /* File upload */

                $('.photo-preview input[type=file]').on('change', function() {

                    var $fileInput  = $(this),
                        $fileField  = $(this).parents('.file-field'),
                        files       = ($fileInput)[0].files,
                        $preview    = $fileInput.parent().find('label[for="' + $fileInput.attr('id') + '"]'),
                        $container  = $fileInput.parent(),
                        $addIcon    = $preview.find('i.add'),
                        $removeIcon = $fileField.find('i.remove'),
                        $spinIcon   = $preview.find('.preloader-wrapper'),
                        reader      = new FileReader();

                    reader.addEventListener('loadstart', function () {
                        $addIcon.addClass('hide');
                        $spinIcon.addClass('active');
                    }, false);

                    reader.addEventListener('load', function (e) {
                        $preview.addClass('file-loaded');
                        $preview.css('background-image', 'url(' + reader.result + ')');
                        $container.next('.file-field').addClass('input-active');
                        $container.next('.file-field').find('input[type="file"]').prop('disabled', false);
                    }, false);

                    reader.addEventListener('loadend', function () {
                        $spinIcon.removeClass('active');
                        $removeIcon.removeClass('hide');
                    }, false);

                    var file = files[0];

                    if ( file ) {
                        reader.readAsDataURL(file);
                    }
                });

                $('.photo-preview .file-field i.remove').on(clickEvent(), function(e) {
                    var $fileField  = $(this).parents('.file-field'),
                        $fileInput  = $fileField.find('input[type=file]'),
                        $preview    = $fileInput.parent().find('label[for="' + $fileInput.attr('id') + '"]'),
                        $addIcon    = $preview.find('i.add'),
                        $removeIcon = $fileField.find('i.remove');

                    $fileInput.val('');
                    $preview.css('background-image', 'none');
                    $addIcon.removeClass('hide');
                    $removeIcon.addClass('hide');
                });

                $('form').on('submit', function(e) {
                    var $fileInputs = $(this).find('input[type="file"]');

                    $fileInputs.each(function(i) {
                        if ( $(this)[0].files.length > 0) {
                            $('.file-upload-overlay').addClass('active');
                            $('.file-upload-overlay .preloader-wrapper').addClass('active');
                        }
                    });
                });

                /* Photos view modal */

                $('.photo-preview .modal-trigger').on(clickEvent(), function() {

                    var $slider = $('.carousel.carousel-slider'),
                        id      = $(this).attr('id'),
                        i       = 0;

                    if ( 'label-2' === id ) {
                        i = 1;
                    } else if ( 'label-3' === id ) {
                        i = 2;
                    }

                    $slider.tchCarousel({
                        full_width: true,
                        indicators: true
                    });

                    $slider.tchCarousel('set', i);
                });

                /* Materialize tabs modifications */

                // Take into account nested tabs
                $('ul.tabs').on(clickEvent(), 'a', function() {
                    var selectedTabId = $(this).attr('href'),
                        $subTabs      = $(selectedTabId).find('ul.tabs');

                    if ( $subTabs.length ) {
                        $subTabs.find('li:first-child a').trigger('click');
                    }
                });

                /* Collapsibles */

                $('.collapsible-header').on(clickEvent(), function(e) {
                    // active applies after this fires, so the check is opposite
                    if ( ! $(this).hasClass('active') ) {
                        $(this).find('i').html('keyboard_arrow_down');
                    } else {
                        $(this).find('i').html('keyboard_arrow_right');
                    }
                });

                /* Toasts & Notices */

                var $toastContainer = $('.toast-container');

                $(document).on(clickEvent(), '.toast-close', function(e) {
                    e.preventDefault();
                    $(this).parent().velocity(
                        {
                            marginTop: '-40px',
                            opacity  : 0
                        },
                        {
                            duration: 375,
                            complete: function(el) {
                                $(el).remove();
                                var toastId = $(el).find('.toast-id').data('id');

                                $.ajax({
                                    type: 'post',
                                    url: '/toasts.php',
                                    data: {
                                        id: toastId
                                    },
                                    error: function(response) {
                                        console.log('Error: ' + response);
                                    },
                                    success: function(response) {
                                        console.log(response);
                                    }
                                });
                            }
                        }
                    );
                });

                if ( $('.notices .notice.alert').length ) {
                    Materialize.toast(
                        '<span class="toast-text">You have an important notice!</span>' +
                        '<a href="#" class="btn btn-blue btn-clear btn-flat btn-small waves-dark waves-effect view-notices">' +
                        '    View<i class="material-icons">keyboard_arrow_down</i>' +
                        '</a>',
                        10000,
                        'toast--notice-important'
                    );

                    $('.view-notices').on(clickEvent(), function(e) {
                        e.preventDefault();
                        $('section.notices').velocity('scroll');
                        $('.toast--notice-important').velocity(
                            {
                                marginTop: '-40px',
                                opacity  : 0
                            },
                            {
                                duration: 375,
                                complete: function(el) {
                                    $(el).remove();
                                }
                            }
                        );
                    });
                }

                /* AJAX form saving */
                $('form.use-ajax, form[action="/encounter/save-soap"]').on('submit', function (event, params) {

                    event.preventDefault();

                    var $form      = $(this),
                        $preloader = $('.preloader-wrapper'),
                        $status    = $('.status-text');

                    $preloader.addClass('active');

                    $.post($form.attr('action'), $form.serialize())
                        .done(function () {
                            $preloader.removeClass('active');
                            $status
                                .removeClass('hide')
                                .addClass('color-green')
                                .html('Saved.')
                                .velocity('fadeIn', { duration: 200 })
                                .delay(5000).velocity('fadeOut', { duration: 1000 });
                            if (params && params.onSuccess) {
                                params.onSuccess.apply();
                            }
                        })
                        .fail(function () {
                            $status
                                .removeClass('hide')
                                .addClass('color-red')
                                .html('Save failed.')
                                .velocity('fadeIn', { duration: 200 })
                                .delay(5000).velocity('fadeOut', { duration: 1000 });
                        });
                });

                // Add new dependent
                var $addDependentModal     = $('#add-dependent.modal'),
                    $addDependentForm      = $('.form--add-dependent'),
                    $addDependentInputs    = $addDependentForm.find('input'),
                    $addDependentFirstName = $('#dependent-first-name'),
                    $addDependentLastName  = $('#dependent-last-name'),
                    $addDependentBirthdate = $('#dependent-birthdate'),
                    $addDependentAddButton = $addDependentModal.find('.btn-add'),
                    maxAge                 = 120;

                if (0 < $addDependentBirthdate.length) {
                    $addDependentBirthdate.pickadate({
                        format: 'mmmm d, yyyy',
                        selectYears: maxAge,
                        min: new Date(moment().subtract(maxAge, 'years').format('YYYY-MM-DD')),
                        max: new Date()
                    });
                }

                $addDependentForm.on('change input keyup', function () {
                    var disableAddButton = false;
                    $addDependentInputs.each(function () {
                        if (0 === $(this).val().length) {
                            disableAddButton = true;
                            return false;
                        }
                    });
                    $addDependentAddButton.prop('disabled', disableAddButton);
                    if (disableAddButton) {
                        $addDependentAddButton.addClass('disabled');
                    } else {
                        $addDependentAddButton.removeClass('disabled');
                    }
                });

                $addDependentModal.find('.btn-add').on(clickEvent(), function (e) {
                    e.preventDefault();

                    var newDependentInfo = `${$addDependentFirstName.val()} ${$addDependentLastName.val()}`;

                    $.post('/patient-dependent-add', $addDependentForm.serialize())
                        .done(function (dependent) {
                            var $collection      = $('.create-appointment-step').find('form ul.collection'),
                                onCompleteTarget = $addDependentForm.data('on-complete-target');

                            $collection.append(`
                                <li class="collection-item">
                                    <button name="patient_person_id" type="submit" value="${dependent.person_id}"
                                        class="btn btn-secondary btn-clear waves-effect waves-green">
                                        ${newDependentInfo}
                                        <i class="material-icons">keyboard_arrow_right</i>
                                    </button>
                                </li>
                            `);

                            $('#add-dependent').closeModal();
                            $addDependentInputs.val('').blur();

                            if (onCompleteTarget) {
                                window.location = onCompleteTarget.replace('{person_id}', dependent.person_id);
                            }
                        })
                        .fail(function () {
                            console.log('Failed adding dependent', arguments);
                        });
                });
            },
            finalize: function() { // JavaScript to be fired on all pages, after page specific JS is fired

                $('.ui-resizable-handle').append('<div class="ui-resizable-handle-icon">' +
                    '<i class="material-icons">more_vert</i>' +
                '</div>');

            }
        },

        // Patient - Create Appointment
        'patient': {
            init: function() {

                /* Step - Location */

                // Automatically populate timezone input field
                var $inputTimezone = $('input[name="timezone"]');
                if (!$inputTimezone.length || 0 !== $inputTimezone.val().length) {
                    $inputTimezone.val($inputTimezone.val())
                }

                /* Step - Encounter type */

                var $encounterTypeForm = $('.form--encounter-type');

                $encounterTypeForm.find('.btn-large-square').on(clickEvent(), function(e) {
                    var encounterTypeId = $(this).data('encounter-type-id');
                    $('input[name="encounter_type_id"]').val(encounterTypeId);

                    if ( '2' == encounterTypeId ) {
                        $('.phone-alert').show();
                    } else {
                        $('.phone-alert').hide();
                    }
                });

                $encounterTypeForm.find('input[type="tel"]').on('change input keyup', function() {
                    var $btnContinue = $(this).parents('.modal').find('.btn-continue');

                    if (10 <= $(this).val().replace(/[^0-9]/g, '').length) {
                        $btnContinue
                            .prop('disabled', false) // <button>
                            .removeClass('disabled');  // <a>

                    } else {
                        $btnContinue
                            .prop('disabled', true)  // <button>
                            .addClass('disabled'); // <a>
                    }
                });

                // populate time when patient selects an appointment slot
                var $selectApptModal = $('.patient .create-appointment-modal');
                var selectedDateTime = null;

                $selectApptModal.find('li').on('click', function() {
                    selectedDateTime = $(this).data('timestamp');

                    if (!selectedDateTime ) {
                        return;
                    }

                    var selectedDate = new Date(selectedDateTime.toString());

                    var displayDate = moment(selectedDate).format('dddd, MMMM D, YYYY');
                    var displayTime = moment(selectedDate).format('h:mm A');
                    $('.js-populateDateFromTimestamp').html(displayDate);
                    $('.js-populateTimeFromTimestamp').html($(this).find('.appointment-time').text());
                    $('#scheduled_start_date_time').val( $(this).data('apptdatetime') );

                    $('.form--confirm-datetime').submit();
                });

                /* Step - Select date/time */

                var $appointmentPicker = $('#datetime-picker');

                if ( $appointmentPicker.length ) {
                    var $btnSet = $appointmentPicker.not(':disabled').find('.btn-continue');

                    $btnSet.on(clickEvent(), function() {
                        $('.form--select-datetime').addClass('hide');

                        $('.form--confirm-datetime')
                            .removeClass('hide')
                            .css('opacity', 0)
                            .velocity('fadeIn', {
                                duration: 300
                            });
                    });
                }

                $('.days-container .paginator').on(clickEvent(), function(e) {
                    e.preventDefault();

                    if ( ! $(this).hasClass('disabled') ) {

                        var $container = $(this).parents('.days-container');

                        if ( 'prev' == $(this).data('direction') ) {
                            var $newContainer = $container.prev();

                        }

                        if ( 'next' == $(this).data('direction') ) {
                            var $newContainer = $container.next();
                        }

                        if ( $newContainer.is(':first-child') ) {
                            $newContainer.find('.prev-day').addClass('disabled');
                        } else if ( $newContainer.is(':last-child') ) {
                            $newContainer.find('.next-day').addClass('disabled');
                        } else {
                            $('.paginator').removeClass('disabled');
                        }

                        $container
                            .removeClass('active')
                            .addClass('hide');
                        $newContainer
                            .removeClass('hide')
                            .addClass('active');
                    }
                });

                /* Symptoms + Images */

                var $symptomsImagesForm = $('.form--symptoms-images');

                $symptomsImagesForm.find('button[type="submit"]').on(clickEvent(), function(e) {
                    e.preventDefault();

                    var symptoms = $('#symptoms').val();

                    if ( $('.alert').length ) {
                        $('.alert').remove();
                    }

                    if ( ! symptoms.length ) {
                        $('<div class="alert alert-danger">Please enter your symptoms</div>').insertBefore($symptomsImagesForm);
                        $('#symptoms').addClass('invalid');
                        $('.page-header').velocity('scroll');

                    } else {
                        $symptomsImagesForm.submit();
                    }
                });

            },
            finalize: function() {
            }
        },

        // Physician
        'physician': {
            init: function() {

                /* Wait queue item counter */
                setInterval(setTime, 1000);

                function setTime() {
                    let selector = '.appointments-queue-list .appointment,' +
                                   '.view-appointment:not(.archived)';
                    $(selector).each(function(i) {
                        var $hours   = $(this).find('.status-datetime .h'),
                            $minutes = $(this).find('.status-datetime .i'),
                            $seconds = $(this).find('.status-datetime .s'),
                            currentSeconds = parseInt($seconds.text()),
                            currentMinutes = parseInt($minutes.text()),
                            currentHours   = parseInt($hours.text());

                        if (!$(this).hasClass('status--done')) {
                            currentSeconds ++;

                            if ( 0 === (currentSeconds % 60) ) {
                                currentMinutes ++;

                                if ( 0 === (currentMinutes % 60) ) {
                                    currentMinutes = 0;
                                    currentHours ++;
                                }
                            }

                            $seconds.html( pad(currentSeconds % 60) );
                            $minutes.html( pad(currentMinutes) );
                            $hours.html( pad(currentHours) );
                        }
                    });
                }

                function pad(val)
                {
                    var valString = val + '';

                    if ( valString.length < 2 ) {
                        return '0' + valString;
                    } else {
                        return valString;
                    }
                }

                /* Load more appointment days */

                $('.appointments-queue .load-more').on(clickEvent(), function(e) {
                    e.preventDefault();

                    $('.future-appointments').velocity('slideDown', {
                        duration: 300,
                        complete: function(el) {
                            $(el).removeClass('hide');
                            $('.appointments-queue .load-more').hide();
                        }
                    });
                });

                /* Cancel future encounters */

                var targetAppts          = '.appointment.cancelable',
                    $targetAppts         = $(targetAppts),
                    $targetApptsList     = $('.appointments-queue .time-segment').not('.now-appointments'),
                    $formCancelEncounter = $('#form-cancel-encounter'),
                    $cancelBtn           = $('.btn-cancel-encounter');

                var cancelBtn = function($target, action) {
                    if ( 'show' === action ) {
                        $target.addClass('cancel-active');
                    } else if ( 'hide' === action ) {
                        $target.removeClass('cancel-active');
                    } else {
                        // NOOP
                    }
                },

                /* Cancel encounter on mobile via swipe (hammerjs) */
                initCancelEncounterMobile = function() {
                    var hContainer  = document.getElementById('appointments-queue');

                    if ( hContainer ) {
                        var hApptTarget = new Hammer(hContainer);

                        if ( hApptTarget ) {
                            hApptTarget.on('swipe', function(e) {
                                var $target = $(e.target).parents('.appointment.cancelable');

                                // Stop appointment click/tap event
                                $target.on(clickEvent(), function(evt) {
                                    evt.stopPropagation();
                                });

                                if ( 2 === e.direction ) { // left
                                    cancelBtn($target, 'hide');

                                } else if ( 4 === e.direction ) { // right

                                    // Hide all
                                    $targetAppts.each(function(i) {
                                        cancelBtn($(this), 'hide');
                                    });

                                    cancelBtn($target, 'show');

                                } else {
                                    // No up/down
                                }
                            });
                        }
                    }
                },

                initCancelEncounterBtn = function() {
                    $(document).on({
                        mouseenter: function() {
                            $(this).find('.btn-cancel-encounter')
                                .removeClass('hide')
                                .velocity('fadeIn', 200);
                        },
                        mouseleave: function() {
                            $(this).find('.btn-cancel-encounter')
                                .velocity('fadeOut', 150);
                        }
                    }, targetAppts);
                };

                $cancelBtn.on(clickEvent(), function () {
                    $formCancelEncounter.attr(
                        'action',
                        '/encounter/cancel/' + $(this).closest('li').data('encounterid')
                    );
                });

                $formCancelEncounter.on('submit', function (e) {
                    let action         = $formCancelEncounter.attr('action'),
                        encounterId    = action.substr(action.lastIndexOf('/') + 1);

                    e.preventDefault();

                    $.post(action, function(data) {
                        // Remove appointment from list
                        $('.appointments-queue .appointment[data-encounterid="' + encounterId + '"]').velocity('slideUp', {
                            complete: function() {
                                $(this).remove();
                            }
                        });

                        // @todo - Check for empty appointment list and display alert
                    })
                    .fail(function () {
                        console.error('Encounter cancellation failed for ' + encounterId);
                    });
                });

                if ( ! mobile ) {
                    initCancelEncounterBtn();
                } else {
                    initCancelEncounterMobile();
                }


                // EMR PDF integration
                $('button[name="view-notes"]').on(clickEvent(), function () {

                    const
                        $btn        = $(this),
                        encounterId = $btn.data('encounter-id');

                    $btn.closest('form').trigger('submit', {onSuccess: () => {
                        window.open(`/encounter/emr-pdf/${encounterId}`);
                    }});
                });

            },
            finalize: function() {
            }
        },

        // Encounter
        'encounter': {
            init: function() { // JavaScript to be fired on the Encounter pages

                /* Resize encounter session video */

                if ( mobile ) {
                    $('.ui-resizable').removeClass('.ui-resizable');
                }

                $.ui.plugin.add("resizable", "alsoResizeReverse", {
                    start: function() {
                        var that = $(this).resizable( "instance" ),
                            o = that.options;

                        $(o.alsoResizeReverse).each(function() {
                            var el = $(this);
                            el.data("ui-resizable-alsoresizeReverse", {
                                width : parseInt((el.width() - 15), 10),
                                height: parseInt(el.height(), 10),
                                left  : parseInt(el.css("left"), 10),
                                top   : parseInt(el.css("top"), 10)
                            });
                        });
                    },
                    resize: function(event, ui) {
                        var that = $(this).resizable( "instance" ),
                            o = that.options,
                            os = that.originalSize,
                            op = that.originalPosition,
                            delta = {
                                height: (that.size.height - os.height) || 0,
                                width : (that.size.width - os.width) || 0,
                                top   : (that.position.top - op.top) || 0,
                                left  : (that.position.left - op.left) || 0
                            };

                        $(o.alsoResizeReverse).each(function() {
                            var el = $(this), start = $(this).data("ui-resizable-alsoresize-reverse"), style = {},
                            css = el.parents(ui.originalElement[0]).length ?
                            [ "width", "height" ] :
                            [ "width", "height", "top", "left" ];

                            $.each(css, function(i, prop) {
                                var sum = (start[prop] || 0) - (delta[prop] || 0);
                                if (sum && sum >= 0) {
                                    style[prop] = sum || null;
                                }
                            });

                            el.css(style);
                        });
                    },
                    stop: function() {
                        $(this).removeData("resizable-alsoresize-reverse");
                    }
                });

                var $interactionWin = $('.encounter-session--interaction'),
                    $pApptInfoWin   = $('.encounter-session--patient-appointment-info');

                $interactionWin.resizable({
                    handles: 'e',
                    minWidth: $interactionWin.width(),
                    maxWidth: $pApptInfoWin.width(),
                    alsoResizeReverse: $pApptInfoWin
                });

                /*  Star rating */

                $('.star-rate a').on(clickEvent(), function(e) {
                    e.preventDefault();

                    $('.star-rate a').removeClass('active');

                    $(this).addClass('active');

                    var rate = parseInt($(this).data('rating'), 10);

                    $('.rating-feedback').velocity('slideDown').velocity('scroll');

                    $('.input-starRating').val(rate);
                });

            },
            finalize: function() {

                if ( $('body').hasClass('patient') ) {
                    if ( $('.encounter-loading').length ) {
                        var msg = 'By starting this session, you agree to our terms and conditions.';
                        Materialize.toast(msg, 6000);
                    }
                }

            }
        },

        // Home page
        'home': {
            init: function() { // JavaScript to be fired on the home page
            },
            finalize: function() { // JavaScript to be fired on the home page, after the init JS
            }
        },

        // select location
        'select_location': {
            init: function() {

            }
        }
    };


    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Delta;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() { // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery);
